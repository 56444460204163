import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT, API_PARADISE } from "src/constants/minutApi"
import { PointMetrics } from "src/data/devices/logic/deviceConstants"
import {
  IParadiseDeviceEventsFilter,
  IParadiseDeviceEventsResponse,
  IParadiseDeviceListFilter,
  IParadiseDeviceMetricsFilter,
  IParadiseDeviceMetricsResponse,
  IParadiseDevicePointMessagesFilter,
  IParadiseDevicePointMessagesResponse,
  IParadiseDeviceResponse,
  IParadiseDevicesCountResponse,
  IParadiseDeviceSetFirmwareBody,
  IParadiseDeviceSetFirmwareResponse,
  IParadiseDeviceWarrantyStatusResponse,
  IParadiseUpdateDeviceBody,
  IParadiseUpdateDeviceTagsBody,
  TParadiseDevice,
  TParadiseDevicesQueryParams,
  TParadiseDevicesResponse,
  TParadiseHardwareVersionsResponse,
} from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

import {
  paradiseDevicesKeys,
  useParadiseDeviceCache,
} from "./paradiseDeviceQueryCache"

export function useFetchParadiseDashboardDevices({
  filter,
  options,
}: {
  filter?: IParadiseDeviceListFilter
  options?: UseQueryOptions<
    IParadiseDeviceResponse,
    AxiosError,
    IParadiseDeviceResponse,
    ReturnType<typeof paradiseDevicesKeys.dashboardDeviceList>
  >
}) {
  async function fetchParadiseDevices() {
    const response = await minutApiHttpClient.get(`${API_PARADISE}/devices`, {
      params: filter && {
        offset: filter.offset,
        limit: filter.limit,
        "filter[home]": filter.home_id,
        "filter[device_mac]": filter.device_mac,
        "filter[device_id]": filter.device_id,
        "filter[owner]": filter.owner_id,
        "filter[description]": filter.description,
        "filter[offline]": filter.offline,
        "filter[active]": filter.active,
        "filter[hardware_version]": filter.hardware_version,
        "filter[firmware.installed]": filter.installed_firmware,
        "filter[or_tags]": filter.or_tags,
        ...(filter?.sort && {
          [`sort[${filter.sort}]`]: filter.sort_by ?? "desc",
        }),
      },
    })

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.dashboardDeviceList(filter),
    queryFn: fetchParadiseDevices,
    ...options,
  })
}

export function useFetchParadiseDevice({
  deviceId,
  options,
}: {
  deviceId: string
  options?: UseQueryOptions<
    TParadiseDevice,
    AxiosError,
    TParadiseDevice,
    ReturnType<typeof paradiseDevicesKeys.device>
  >
}) {
  async function fetchParadiseDevice() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/devices/${deviceId}`
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.device(deviceId),
    queryFn: fetchParadiseDevice,
    ...options,
  })
}

export function useFetchParadiseDevicePointMessages({
  deviceId,
  filter,
  options,
}: {
  deviceId: string
  filter?: IParadiseDevicePointMessagesFilter
  options?: UseQueryOptions<
    IParadiseDevicePointMessagesResponse,
    AxiosError,
    IParadiseDevicePointMessagesResponse,
    ReturnType<typeof paradiseDevicesKeys.pointMessages>
  >
}) {
  async function fetchParadiseDevicePointMessages() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/devices/${deviceId}/point_messages`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.pointMessages(deviceId, filter),
    queryFn: fetchParadiseDevicePointMessages,
    ...options,
  })
}

export function useFetchParadiseDeviceWarrantyStatus({
  deviceId,
  options,
}: {
  deviceId: string
  options?: UseQueryOptions<
    IParadiseDeviceWarrantyStatusResponse,
    AxiosError,
    IParadiseDeviceWarrantyStatusResponse,
    ReturnType<typeof paradiseDevicesKeys.warrantyStatus>
  >
}) {
  async function fetchParadiseDeviceWarrantyStatus() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/devices/${deviceId}/warranty_status`
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.warrantyStatus(deviceId),
    queryFn: fetchParadiseDeviceWarrantyStatus,
    ...options,
  })
}

export function useFetchDevicePointMessage({
  deviceId,
  timestamp,
  messageType,
  options,
}: {
  deviceId: string
  timestamp: number
  messageType: number
  options?: UseQueryOptions<
    string,
    AxiosError,
    string,
    ReturnType<typeof paradiseDevicesKeys.pointMessage>
  >
}) {
  async function fetchDevicePointMessage() {
    // Using "unknown" here due to complex endpoint with no typing
    const response = await minutApiHttpClient.get<unknown>(
      `${API_PARADISE}/devices/${deviceId}/point_messages/${timestamp}/${messageType}`
    )

    return JSON.stringify(response.data)
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.pointMessage(
      deviceId,
      timestamp,
      messageType
    ),
    queryFn: fetchDevicePointMessage,
    ...options,
  })
}

export function useFetchParadiseDeviceEvents({
  deviceId,
  filter,
  options,
}: {
  deviceId: string
  filter?: IParadiseDeviceEventsFilter
  options?: UseQueryOptions<
    IParadiseDeviceEventsResponse,
    AxiosError,
    IParadiseDeviceEventsResponse,
    ReturnType<typeof paradiseDevicesKeys.deviceEvents>
  >
}) {
  async function fetchParadiseDeviceEvents() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/devices/${deviceId}/deviceevents`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.deviceEvents(deviceId, filter),
    queryFn: fetchParadiseDeviceEvents,
    ...options,
  })
}

export function usePutParadiseUpdateDevice() {
  const paradiseDeviceCache = useParadiseDeviceCache()

  async function putParadiseUpdateDevice({
    deviceId,
    body,
  }: {
    deviceId: string
    body: IParadiseUpdateDeviceBody
  }) {
    const response = await minutApiHttpClient.put<TParadiseDevice>(
      `${API_PARADISE}/devices/${deviceId}`,
      body
    )

    return response.data
  }

  return useMutation({
    mutationFn: putParadiseUpdateDevice,
    onSuccess: (data, vars) => {
      return Promise.all([
        paradiseDeviceCache.invalidateParadiseDevice(vars.deviceId),
        paradiseDeviceCache.invalidateParadiseDeviceList(),
      ])
    },
  })
}

export function usePutParadiseUpdateDeviceTags() {
  const paradiseDeviceCache = useParadiseDeviceCache()

  async function putParadiseUpdateDeviceTags({
    deviceId,
    body,
  }: {
    deviceId: string
    body: IParadiseUpdateDeviceTagsBody
  }) {
    const response = await minutApiHttpClient.put<TParadiseDevice>(
      `${API_PARADISE}/devices/${deviceId}/tags`,
      body
    )

    return response.data
  }

  return useMutation({
    mutationFn: putParadiseUpdateDeviceTags,
    onSuccess: (data, vars) => {
      return Promise.all([
        paradiseDeviceCache.invalidateParadiseDevice(vars.deviceId),
        paradiseDeviceCache.invalidateParadiseDeviceList(),
      ])
    },
  })
}

export function usePutSetDeviceFirmware() {
  const paradiseDeviceCache = useParadiseDeviceCache()

  async function putSetDeviceFirmware({
    deviceId,
    body,
  }: {
    deviceId: string
    body: IParadiseDeviceSetFirmwareBody
  }) {
    const response =
      await minutApiHttpClient.put<IParadiseDeviceSetFirmwareResponse>(
        `${API_PARADISE}/devices/${deviceId}/firmware`,
        body
      )

    return response.data
  }
  return useMutation({
    mutationFn: putSetDeviceFirmware,
    onSuccess: (data, vars) => {
      return Promise.all([
        paradiseDeviceCache.invalidateParadiseDevice(vars.deviceId),
        paradiseDeviceCache.invalidateParadiseDeviceList(),
      ])
    },
  })
}

export function useFetchParadiseDeviceMetrics({
  deviceId,
  metricId,
  filter,
  options,
}: {
  deviceId: string
  metricId: PointMetrics
  filter: IParadiseDeviceMetricsFilter
  options?: UseQueryOptions<
    IParadiseDeviceMetricsResponse,
    AxiosError,
    IParadiseDeviceMetricsResponse,
    ReturnType<typeof paradiseDevicesKeys.deviceMetrics>
  >
}) {
  async function fetchParadiseDeviceMetrics() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/devices/${deviceId}/device_metrics/${metricId}`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.deviceMetrics(deviceId, metricId, filter),
    queryFn: fetchParadiseDeviceMetrics,
    ...options,
  })
}
export function useFetchParadiseDevicesCount(props?: {
  options: UseQueryOptions<
    IParadiseDevicesCountResponse,
    AxiosError,
    IParadiseDevicesCountResponse,
    ReturnType<typeof paradiseDevicesKeys.count>
  >
}) {
  async function fetchParadiseDevicesCount() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/devices/count`
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.count(),
    queryFn: fetchParadiseDevicesCount,
    ...props?.options,
  })
}

export function useFetchParadiseHardwareVersions(props?: {
  options: UseQueryOptions<
    TParadiseHardwareVersionsResponse,
    AxiosError,
    TParadiseHardwareVersionsResponse,
    ReturnType<typeof paradiseDevicesKeys.hardwareVersions>
  >
}) {
  async function fetchHardwareVersions() {
    const response =
      await minutApiHttpClient.get<TParadiseHardwareVersionsResponse>(
        `${API_PARADISE}/devices/hardware_versions`
      )

    return response.data
  }

  return useQuery({
    ...props?.options,
    queryKey: paradiseDevicesKeys.hardwareVersions(),
    queryFn: fetchHardwareVersions,
  })
}

// ts-prune-ignore-next
export function useFetchParadiseDevices(props?: {
  filter?: TParadiseDevicesQueryParams
  options?: UseQueryOptions<
    TParadiseDevicesResponse,
    AxiosError,
    TParadiseDevicesResponse,
    ReturnType<typeof paradiseDevicesKeys.deviceList>
  >
}) {
  async function fetchParadiseDevices() {
    const response = await minutApiHttpClient.get(`${API_DEFAULT}/devices`, {
      params: props?.filter,
    })

    return response.data
  }

  return useQuery({
    queryKey: paradiseDevicesKeys.deviceList(props?.filter),
    queryFn: fetchParadiseDevices,
    ...props?.options,
  })
}
